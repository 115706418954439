import { createSlice } from "@reduxjs/toolkit";

import { packageService } from "./packageService";

const initialState = {
  order_id: "",
};

export const packageSlice = createSlice({
  name: "package",
  initialState,

  extraReducers: (builder) => {
    builder.addMatcher(
      packageService.endpoints.savePackage.matchFulfilled,
      (state, { payload }) => {
        state.order_id = payload?.payload?.order_id;
      },
    );
  },
});

export const selectOrderId = (state) => state.package.order_id;

export default packageSlice.reducer;
