import React, { useCallback } from "react";
import { useForm } from "react-hook-form";

import { useSimpleRegisterMutation } from "../../../../store/authentication/authService";
import { InputsRules } from "../../../../constants/auth";

import AuthTitle from "../AuthTitle";
import AuthInput from "../AuthInput";
import AuthRegistrationFooter from "../AuthRegistrationFooter";
import AuthSocial from "../AuthSocial";

import { Container } from "./styles";

const AuthRegistration = ({ changeTab }) => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: { email: "", policy: false },
    mode: "all",
  });

  const [registration, { isLoading }] = useSimpleRegisterMutation();

  const goToSignIn = useCallback(() => changeTab("sign-in"), []);

  const onSubmit = useCallback(async ({ email, policy }) => {
    try {
      const response = await registration({
        email,
        policy,
      }).unwrap();

      if (typeof window.fbq === "function") {
        window.fbq("init", window.fbPid, {
          em: response?.email,
          fn: response?.firstName,
          ln: response?.lastName,
        });
        window.fbq("track", "CompleteRegistration");
      }
    } catch (error) {
      setError("email", {
        type: "custom",
        message:
          error?.data?.error?.details?.email[0] || error?.data?.error?.message,
      });
    }
  }, []);

  return (
    <Container>
      <AuthTitle
        title="Sign up"
        subTitle="Already have a Cart2Cart account?"
        titleLink="Sign In"
        goTo={goToSignIn}
      />

      <AuthInput
        error={errors?.email?.message}
        control={control}
        label="Email"
        name="email"
        rules={{
          required: InputsRules.required,
          pattern: InputsRules.patternEmail,
        }}
      />

      <AuthRegistrationFooter
        error={errors?.policy?.message}
        onSubmit={handleSubmit(onSubmit)}
        isValid
        isLoading={isLoading}
        control={control}
      />

      <AuthSocial title="Sign up" />
    </Container>
  );
};

export default AuthRegistration;
