import React, { memo, useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";

import { useLoginMutation } from "../../../../store/authentication/authService";
import { LOGIN_INPUT, LOGIN_VALUE } from "../../../../constants/auth";

import AuthTitle from "../AuthTitle";
import AuthFormInputs from "../AuthFormInputs";
import AuthLoginFooter from "../AuthLoginFooter";
import AuthSocial from "../AuthSocial";

import { Container, DisabledInput } from "./styles";

const AuthLogin = ({ changeTab }) => {
  const {
    handleSubmit,
    setError,
    control,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "all", defaultValues: LOGIN_VALUE });

  const [login, { isLoading }] = useLoginMutation();

  const goToSignUp = useCallback(() => changeTab("sign-up"), [changeTab]);
  const goToForgotPassword = useCallback(
    () => changeTab("forgot-password"),
    [changeTab],
  );

  const onSubmit = useCallback(async ({ email, password, saved }) => {
    try {
      await login({
        email,
        password,
      }).unwrap();

      if (saved) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);
        localStorage.setItem("saved", saved);
      } else {
        localStorage.removeItem("email");
        localStorage.removeItem("password");
        localStorage.removeItem("saved");
      }
    } catch (error) {
      const errors = error?.data?.error?.details;

      if (Object.keys(errors).length) {
        Object.keys(errors).forEach((field) => {
          setError(`${field}`, { type: "custom", message: errors[field] });
        });

        return;
      }

      setError("password", {
        type: "custom",
        message: error?.data?.error?.message,
      });
    }
  }, []);

  const saveAuth = ({ email, password, saved }) => {
    if (saved) {
      localStorage.setItem("email", email);
      localStorage.setItem("password", password);
      localStorage.setItem("saved", saved);
    } else {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
      localStorage.removeItem("saved");
    }
  };

  useEffect(() => {
    const savedLogin = localStorage.getItem("email");
    const savedPassword = localStorage.getItem("password");
    const saved = localStorage.getItem("saved");

    if (savedLogin && savedPassword && saved) {
      reset();
      setValue("email", savedLogin);
      setValue("password", savedPassword);
      setValue("saved", saved);
    }
  }, []);

  return (
    <Container onSubmit={handleSubmit(onSubmit)}>
      <AuthTitle
        title="Sign in"
        subTitle="New to Cart2Cart?"
        titleLink="Sign Up"
        goTo={goToSignUp}
      />

      <AuthFormInputs inputs={LOGIN_INPUT} control={control} errors={errors} />

      <AuthLoginFooter
        onSubmit={handleSubmit(onSubmit)}
        saveAuth={handleSubmit(saveAuth)}
        goToForgotPassword={goToForgotPassword}
        control={control}
        isValid
        isLoading={isLoading}
      />

      <AuthSocial title="Sign in" />

      <DisabledInput type="submit" />
    </Container>
  );
};

export default memo(AuthLogin);
